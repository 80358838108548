import { Controller } from 'stimulus'
import { Datepicker, DateRangePicker } from 'vanillajs-datepicker'
import de from 'vanillajs-datepicker/js/i18n/locales/de'

Object.assign(Datepicker.locales, de);

export default class extends Controller {
  connect() {
    const datepicker = new DateRangePicker(this.element, {
      weekStart: 1,
      todayHighlight: true,
      language: 'de',
    }); 
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["user", "estimateType"]

  connect() {
    const checkedEstimateType = this.estimateTypeTargets.find( (estimateType) => estimateType.checked == true )

    if (checkedEstimateType && checkedEstimateType.value == "external") {
      this.hideUser()
    } 
  }

  showUser(e) {
    this.userTarget.classList.remove("hidden")
  }

  hideUser(e) {
    // TODO: von slim-select entkoppeln
    const slimSelect = this.userTarget.querySelector("select").slim
    if (slimSelect) { slimSelect.set("") }
    this.userTarget.classList.add("hidden")
  }

  clearHiddenInputs(e) {
    const hiddenInputs = this.element.querySelectorAll(".hidden input")
    hiddenInputs.forEach( (input) => input.value = "" )
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['tab', 'panel']
  static values = { activeTab: String, activeClass: String }

  connect() {
    if (this.hasActiveTabValue && this.activeTabValue != "") {
      this.tabTargets.forEach( (tab, index) => {
        if (this.isActiveTab(tab)) {
          this.panelTargets[index].classList.remove('hidden') 
        } else {
          this.panelTargets[index].classList.add('hidden') 
        }
      })
    } else {
      const firstTab = this.tabTargets[0]
      if (firstTab.type == "radio") {
        this.tabTargets[0].checked = true
      } else {
        this.tabTargets[0].classList.add(this.activeClassValue) 
      }

      this.panelTargets.forEach( (panel) => panel.classList.add("hidden") )
      this.panelTargets[0].classList.remove("hidden")
    }
  }

  disconnect() {
  }

  change(e) {
    const tabIndex = this.tabTargets.indexOf(e.target)

    this.panelTargets.forEach( (panel) => panel.classList.add("hidden") )
    this.tabTargets.forEach( (tab) => tab.classList.remove(this.activeClassValue) )
    this.tabTargets[tabIndex].classList.add(this.activeClassValue)
    this.panelTargets[tabIndex].classList.remove("hidden")
  }

  isActiveTab(tab) {
    if (tab.type == "radio") {
      return ( tab.value == this.activeTabValue )
    } else {
      return ( tab.dataset.tabValue == this.activeTabValue )
    }
  }
}

import { Controller } from "stimulus"
import Gantt from 'frappe-gantt'

export default class extends Controller {
  static values = { projects: Array }
  static targets = [ "svg" ]

  connect() {
    this.gantt = new Gantt(this.svgTarget, this.projectsValue, {
      view_modes: ['Day', 'Week', 'Month'],
      view_mode: 'Month',
      bar_height: 30,
    })

    // manually override height
    // see https://github.com/frappe/gantt/issues/91#issuecomment-485149097
    const newHeight = this.gantt.$svg.getAttribute('height') - 100;
    this.gantt.$svg.setAttribute('height', newHeight)
  }

  changeView(event) {
    const viewMode = event.currentTarget.dataset.ganttViewMode

    if (typeof( viewMode ) !== undefined) {
      this.gantt.change_view_mode(viewMode)
    }
  }
}
